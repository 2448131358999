var resizeTimer = false;
window.addEventListener('scroll', onScroll);
window.addEventListener('resize', function() {
  if (resizeTimer !== false) { clearTimeout(resizeTimer); }
  resizeTimer = setTimeout(onResized, 200);
});
function onScroll() {
  navigation.onScroll();
}
function onResized() {
  navigation.onResized();
}

var navigation = {
  init: function() {
    this.limit = 3000;
    this.scPos = null;
    this.scTop = window.pageYOffset;
    this.isOpened = false;
    this.isUpScroll = false;
    this.isFixed = false;
    this.rootElm = $('body');
    this.header = $('.js-header');
    this.container = $('.l-container');
    $('.js-navClose').on('transitionend', this.transitionEnd.bind(this));
    $('.js-navOpen, .js-navClose').on('click', this.navToggle.bind(this));
    this.onResized();
  },
  navToggle: function() {
    this.isOpened = !this.isOpened;
    if (this.isOpened) {
      this.scPos = window.pageYOffset;
      this.header.addClass('anima');
      this.rootElm.addClass('nav-open');
      this.container.css({ top: -this.scPos });
    } else {
      this.header.scrollTop(0).addClass('anima');
      this.rootElm.removeClass('nav-open');
      this.container.removeAttr('style');
      $(window).scrollTop(this.scPos);
    }
  },
  onScroll: function() {
    this.headerOver();
    if (this.isOpened) { return; }
    this.scrollUp();
  },
  autoActive: function() {
    if (!this.isUpScroll && this.isFixed) {
      this.isUpScroll = true;
      this.header.addClass('active anima');
    }
  },
  scrollUp: function() {
    var scTop = window.pageYOffset;
    if (this.scTop < scTop) {
      if (this.isUpScroll && this.isFixed) {
        this.isUpScroll = false;
        this.header.addClass('anima').removeClass('active');
      }
    } else {
      if (!this.isUpScroll && this.isFixed) {
        this.isUpScroll = true;
        this.header.addClass('active anima');
      }
    }
    if (this.timer !== false) { clearTimeout(this.timer); }
    this.timer = setTimeout(this.autoActive.bind(this), this.limit);
    this.scTop = scTop;
  },
  headerOver: function() {
    if (this.scTop === 0) {
      this.isFixed = this.isUpScroll = false;
      this.header.removeClass('active anima fixed');
    } else if (this.scTop > this.headerHeight) {
      if (!this.isFixed) {
        this.isFixed = true;
        this.header.addClass('fixed');
      }
    } else {
      if (this.isFixed) {
        this.isFixed = false;
        this.header.removeClass('fixed');
      }
    }
  },
  onResized: function() {
    var _this = navigation;
    _this.headerHeight = _this.header.innerHeight();
  },
  transitionEnd: function() {
    this.header.removeClass('anima');
  }
}
navigation.init();
